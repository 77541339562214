import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axiosInstance from '../axiosConfig';
import { Link, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Cookies from 'js-cookie';

// const stripePromise = loadStripe('pk_test_51PoFNCRsETdjcfB7zTywrtJbNn6UPBoASAHVtTsnswuhn4lePjG7xOMFC48EVTusgSWJjWmJBlxO0lDPvYmmuAom00bsyH2Ke6');
const stripePromise = loadStripe('pk_live_51PoFNCRsETdjcfB7u0auLNLvRcl45KGBnuOHsuhJhi1tsaaHwOHQ9APE6HVPO06jh1XXOYSRmf8H5RQHpuFdIb7Y00zx1U0r0X');

// Function to get today's date in YYYY-MM-DD format
const getTodayDate = () => {
    // Get the date in UTC
    const now = new Date();
    const utcYear = now.getUTCFullYear();
    const utcMonth = String(now.getUTCMonth() + 1).padStart(2, '0');
    const utcDay = String(now.getUTCDate()).padStart(2, '0');
    return `${utcYear}-${utcMonth}-${utcDay}`;
};

// Function to check if a date is valid and not in the past
const isValidDate = (dateString) => {
    if (!dateString) return false;
    
    // Convert both dates to UTC midnight for comparison
    const now = new Date();
    const utcToday = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    
    const selected = new Date(dateString);
    const utcSelected = Date.UTC(selected.getUTCFullYear(), selected.getUTCMonth(), selected.getUTCDate());
    
    return utcSelected >= utcToday;
};

// Function to format date for display
const formatDisplayDate = (dateString) => {
    if (!dateString) return '';
    
    // Get the date input element to read its displayed value
    const dateInput = document.querySelector('input[type="date"]');
    if (dateInput) {
        // Return the actual displayed value from the input
        return dateInput.value.split('-').reverse().join('/');
    }
    return dateString;
};

// Function to convert DD/MM/YYYY to YYYY-MM-DD for input value
const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
};

//change to testing
const ListingDetail = () => {
    const { id } = useParams();
    const [listing, setListing] = useState({});
    const [realtor, setRealtor] = useState({});
    const [price, setPrice] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [selectedMediaType, setSelectedMediaType] = useState('image');
    const [paymentType, setPaymentType] = useState("");
    const [amount, setAmount] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [listingTitles, setListingTitles] = useState([]);
    const [selectedListing, setSelectedListing] = useState("");
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [penalty, setPenalty] = useState(0); 
    const [adminPenalty, setAdminPenalty] = useState(0); 
    const [dateError, setDateError] = useState("");

    // Format the current date for display
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    // Update date selection handler
    const handleDateChange = (e) => {
        const newDate = e.target.value;
        console.log('Selected date:', newDate); // Debug log
        
        if (isValidDate(newDate)) {
            setSelectedDate(newDate);
            setDateError("");
        } else {
            setDateError("Please select today or a future date.");
            setSelectedDate("");
        }
    };

    const handleCheckout = async () => {
        try {
            if (!selectedDate) {
                alert('Please select a payment date.');
                return;
            }
            
            if (!isValidDate(selectedDate)) {
                alert('Please select a valid date that is not in the past.');
                setSelectedDate("");
                return;
            }
            
            const stripe = await stripePromise;
            const csrfToken = Cookies.get('csrftoken');
            const amountValue = parseFloat(amount);
            const penaltyValue = parseFloat(penalty);
            const totalAmount = amountValue + penaltyValue;
            
            if (isNaN(amountValue) || amountValue <= 0) {
                alert('Please enter a valid amount.');
                return;
            }
            
            if (!selectedListing) {
                alert('Please select a property.');
                return;
            }
            
            if (!paymentType) {
                alert('Please select a payment type.');
                return;
            }
            
            const selectedListingData = listingTitles.find(listing => listing.id === parseInt(selectedListing));
            const selectedListingTitle = selectedListingData ? selectedListingData.title : "Unknown Listing";

            console.log('Sending checkout request with data:', {
                listing_id: selectedListing,
                listing_title: selectedListingTitle,
                payment_type: paymentType,
                amount_paid: amountValue.toFixed(2),
                selected_date: selectedDate,
                penalty_applied: penaltyValue.toFixed(2),
                total_amount: totalAmount.toFixed(2)
            });

            const response = await axiosInstance.post(
                '/payments/create-checkout-session/',
                {
                    listing_id: selectedListing,
                    listing_title: selectedListingTitle,
                    payment_type: paymentType,
                    amount_paid: amountValue.toFixed(2),
                    selected_date: selectedDate
                },
                {
                    headers: {
                        'X-CSRFToken': csrfToken,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            const session = response.data;
            if (session && session.id) {
                stripe.redirectToCheckout({ sessionId: session.id });
            } else {
                console.error("Error: Session ID not found", session);
                alert('An error occurred with the payment session. Please try again.');
            }
        } catch (error) {
            console.error("Error initiating checkout:", error);
            alert('An error occurred. Please try again later.');
        }
    };
    useEffect(() => {
        const fetchPenalty = async () => {
            try {
                const res = await axiosInstance.get('/payments/penalty-amount/');
                console.log('Penalty response:', res.data);
                if (res.data && 'penalty_amount' in res.data) {
                    console.log('Setting admin penalty to:', res.data.penalty_amount);
                    setAdminPenalty(res.data.penalty_amount);
                } else {
                    console.log('Using default penalty amount of 150');
                    setAdminPenalty(150);
                }
            } catch (err) {
                console.error('Error fetching penalty amount:', err);
                setAdminPenalty(150); // Default in case of error
            }
        };
        fetchPenalty();
    }, []);

    useEffect(() => {
        const fetchListing = async () => {
            try {
                const res = await axiosInstance.get(`/api/listings/${id}`);
                setListing(res.data);
                setPrice(numberWithCommas(res.data.price));
                setSelectedMedia(res.data.photo_main);
            } catch (err) {
                console.error(err);
            }
        };

        fetchListing();
    }, [id]);

    useEffect(() => {
        const fetchRealtor = async () => {
            if (listing.realtor) {
                try {
                    const res = await axiosInstance.get(`/api/realtors/${listing.realtor}`);
                    setRealtor(res.data);
                } catch (err) {
                    console.error(err);
                }
            }
        };

        fetchRealtor();
    }, [listing.realtor]);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const res = await axiosInstance.get('/api/accounts/current-user/');
                setCurrentUser(res.data);
            } catch (err) {
                console.error('Error fetching current user:', err);
            }
        };

        fetchCurrentUser();
    }, []);
    useEffect(() => {
        const fetchPaymentTypes = async () => {
            try {
                const res = await axiosInstance.get('/payments/payment-types/');
                setPaymentTypes(res.data); // Expecting an array of tuples like [["monthly", "Monthly Installment"], ...]
            } catch (err) {
                console.error('Error fetching payment types:', err);
            }
        };
    
        fetchPaymentTypes();
    }, []);

    // useEffect(() => {
    //     const fetchListingTitles = async () => {
    //         try {
    //             const res = await axiosInstance.get('/api/listings/listing-titles/');
    //             console.log("Fetched Listing Titles:", res.data); // Debugging
    //             setListingTitles(res.data);
    //         } catch (err) {
    //             console.error('Error fetching listing titles:', err);
    //         }
    //     };

    //     fetchListingTitles();
    // }, []);
    useEffect(() => {
        const fetchListingTitles = async () => {
            try {
                const res = await axiosInstance.get('/api/listings/listing-titles/');
                console.log("Fetched Listing Titles:", res.data); // Debugging
                
                // Ensure the response is an array of objects with id and title
                if (Array.isArray(res.data)) {
                    setListingTitles(res.data);
                } else {
                    console.error("Unexpected response format:", res.data);
                }
            } catch (err) {
                console.error('Error fetching listing titles:', err);
            }
        };
        fetchListingTitles();
    }, []);
    useEffect(() => {
        const fetchPaymentTypes = async () => {
            try {
                const csrfToken = Cookies.get('csrftoken');
                const res = await axiosInstance.get('/payments/payment-types/');
                console.log("Fetched Payment Types:", res.data); // Debugging
                setPaymentTypes(res.data);
            } catch (err) {
                console.error('Error fetching payment types:', err);
            }
        };
        fetchPaymentTypes();
    }, []);
    // useEffect(() => {
    //     const day = parseInt(selectedDate.split("-")[2], 10); // Extract day from YYYY-MM-DD
    //     setPenalty(day > 10 ? adminPenalty : 0); // Apply penalty if after 10th
    // }, [selectedDate, adminPenalty]);
    useEffect(() => {
        const day = parseInt(selectedDate.split("-")[2], 10);
        // Only apply penalty if payment type is monthly
        const appliedPenalty = (paymentType === 'monthly' && day > 10) ? adminPenalty : 0;
        setPenalty(appliedPenalty);
        console.log("Updated Penalty:", appliedPenalty); // Debugging
    }, [selectedDate, adminPenalty, paymentType]); // Add paymentType to dependencies
    
    useEffect(() => {
        // Only add the min date restriction
        const dateInput = document.querySelector('input[type="date"]');
        if (dateInput) {
            dateInput.min = getTodayDate();
        }
    }, []);

    const handleMediaClick = (media, type) => {
        setSelectedMedia(media);
        setSelectedMediaType(type);
    };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }


    const displayMedia = () => {
        const mediaItems = [];

        for (let i = 1; i <= 15; i++) {
            const photo = listing[`photo_${i}`];
            if (photo) {
                mediaItems.push(
                    <div key={i} className='listingdetail__thumbnails__thumbnail'>
                        <img
                            className='listingdetail__thumbnails__thumbnail__image'
                            src={photo}
                            alt=''
                            onClick={() => handleMediaClick(photo, 'image')}
                        />
                    </div>
                );
            }
        }

        for (let i = 1; i <= 5; i++) {
            const video = listing[`video_${i}`];
            if (video) {
                mediaItems.push(
                    <div key={`video_${i}`} className='listingdetail__thumbnails__thumbnail'>
                        <video
                            className='listingdetail__thumbnails__thumbnail__video'
                            onClick={() => handleMediaClick(video, 'video')}
                        >
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                );
            }
        }
       
    

        return (
            <div className='listingdetail__thumbnails'>
                {mediaItems}
            </div>

            
        );
        
    };

    return (
        <div className='listingdetail'>
            <Helmet>
                <title>Real Estate - Listing | {`${listing.title}`}</title>
                <meta name='description' content='Listing detail' />
            </Helmet>
            <div className='row'>
                <div className='listingdetail__breadcrumb'>
                    <Link className='listingdetail__breadcrumb__link' to='/'>Home</Link> / {listing.title}
                </div>
            </div>
            
            <div className="listingdetail__content">
                <div className="listingdetail__media">
                    <div className='listingdetail__display'>
                        {selectedMediaType === 'image' ? (
                            <>
                                <img className='listingdetail__display__image' src={selectedMedia} alt='' />
                                {listing.sold_out && (
                                    <div className="sold-indicator">SOLD!</div>
                                )}
                            </>
                        ) : (
                            <video className='listingdetail__display__video' controls>
                                <source src={selectedMedia} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                    {displayMedia()}
                </div>

                <div className="listingdetail__payment">
                    {listing.sold_out ? (
                        <h2 style={{ marginTop: '2rem', fontSize: '18px', fontWeight: 'bold', marginLeft: '2rem' , color: "red"}}>
                            This property is sold out.
                        </h2>
                    ) : (
                        <>
                            <h2 style={{ marginTop: '1rem', fontSize: '30px', fontWeight: 'bold', marginLeft: '-0.10rem', color: 'hsl(124, 47%, 43%)'}}>
                                {listing.reserved_by && listing.reserved_by !== currentUser?.id ? (
                                    "Please take a look at our other available properties"
                                ) : "Start Payment"}
                            </h2>

                            {listing.reserved_by && listing.reserved_by !== currentUser?.id ? (
                                <>
                                    <p className="reserved-message">
                                        This listing is currently reserved by another user and is not available for purchase.
                                    </p>
                                    <Link 
                                        to="/listings" 
                                        className="view-more-btn"
                                    >
                                        View More Listings
                                    </Link>
                                </>
                            ) : (
                                <div>
                                    {/* Date Selection */}
                                    <div className='date-picker'>
                                        <label>Payment Date:</label>
                                        <div>
                                            <input
                                                type="date"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                min={getTodayDate()}
                                                required
                                                style={{
                                                    width: '100%',
                                                    padding: '8px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    fontSize: '13px'
                                                }}
                                            />
                                            {dateError && <p className="date-error">{dateError}</p>}
                                        </div>
                                    </div>

                                    {/* Penalty Field - Only show for monthly payments */}
                                    {paymentType === 'monthly' && (
                                        <div className={`penalty ${penalty > 0 ? 'has-penalty' : ''}`}>
                                            <p className={penalty > 0 ? 'penalty-warning' : ''}>
                                                {penalty > 0 
                                                    ? "A penalty has been applied because the payment date is after the 10th of the month." 
                                                    : "No penalty applied. Monthly payments made after the 10th of the month will incur a penalty."}
                                            </p>
                                        </div>
                                    )}

                                    <div className='payment-form'>
                                        <label>
                                            Payment Type
                                            <select 
                                                value={paymentType} 
                                                onChange={(e) => setPaymentType(e.target.value)} 
                                                required
                                            >
                                                <option value="">Select Payment Type</option>
                                                {paymentTypes.map(([key, label]) => (
                                                    <option key={key} value={key}>{label}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    
                                    <div className='property'>
                                        <label>
                                            Select Property:
                                            <select 
                                                value={selectedListing} 
                                                onChange={(e) => setSelectedListing(e.target.value)} 
                                                required
                                            >
                                                <option value="">Select a property</option>
                                                {listingTitles.map(listing => (
                                                    <option key={listing.id} value={listing.id}>
                                                        {listing.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    
                                    <div className='amount'>
                                        <label>
                                            Amount
                                            <input 
                                                type="text" 
                                                pattern="[0-9]+(\.[0-9]{1,2})?" 
                                                value={amount} 
                                                onChange={(e) => setAmount(e.target.value)} 
                                                placeholder="0.00"
                                                required 
                                            />
                                        </label>
                                    </div>

                                    {/* Total Amount Display */}
                                    {amount > 0 && (
                                        <div className='total-amount'>
                                            <div>
                                                <span>Payment Amount:</span>
                                                <span>${parseFloat(amount).toFixed(2)}</span>
                                            </div>
                                            {paymentType === 'monthly' && (
                                                <div>
                                                    <span>Penalty:</span>
                                                    <span className={penalty > 0 ? 'penalty-amount' : 'no-penalty'}>
                                                        ${parseFloat(penalty).toFixed(2)}
                                                    </span>
                                                </div>
                                            )}
                                            <div>
                                                <span>Total Amount:</span>
                                                <span>${(parseFloat(amount) + parseFloat(penalty)).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    )}

                                    <button className="payment-btn" onClick={handleCheckout}>Pay with Stripe</button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
                    
            <div className='row'>
                <h2 className="property-heading">Property Description</h2>
                <div className="description-container">
                    <p className='listingdetail__description'>{listing.description}</p>
                </div>
            </div>
            
            <div className='row'>
                <h2 className="property-heading">Property Details</h2>
                <div className="property-info-container">
                    <div className='col-1-of-2'>
                        <ul className='listingdetail__list'>
                            <li className='listingdetail__list__item'>Land Type: {listing.land_type}</li>
                            <li className='listingdetail__list__item'>Price: ${price}</li>
                            <li className='listingdetail__list__item'>Acres: {listing.acres}</li>
                        </ul>
                    </div>
                    <div className='col-1-of-2'>
                        <ul className='listingdetail__list'>
                            <li className='listingdetail__list__item'>Sale Type: {listing.sale_type}</li>
                            <li className='listingdetail__list__item'>Address: {listing.address}</li>
                            <li className='listingdetail__list__item'>City: {listing.city}</li>
                            <li className='listingdetail__list__item'>State: {listing.state}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingDetail;