import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShield, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ProtectiveCovenants = () => {
  return (
    <div className="protective-covenants-page">
      <Helmet>
        <title>Off Grid Developments LLC - Protective Covenants</title>
        <meta name="description" content="Protective covenants that must be followed when purchasing a property" />
      </Helmet>

      {/* Hero Section */}
      <div className="protective-hero">
        <div className="protective-hero-overlay">
          <h1>Protective Covenants</h1>
          <p>Important guidelines that ensure the quality and value of your property investment</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="covenants-content">
        <div className="covenants-intro">
          <FontAwesomeIcon icon={faShield} className="covenant-shield-icon" />
          <p>
            These are Protective Covenants that must be followed when purchasing a property that is bound by these Protective Covenants.
            These covenants are designed to maintain property values and ensure a high quality of life for all residents.
          </p>
        </div>

        <div className="covenants-list">
          <div className="covenant-item">
            <div className="covenant-number">1</div>
            <div className="covenant-text">
              <p>In the event that wood fencing is installed on the property, and said wood fence is going to be painted or stained, said paint or stain color shall be black.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">2</div>
            <div className="covenant-text">
              <p>No loud noises shall be permitted, such as to disturb neighbors in the area, noise may be defined, but, not limited to loud music, autos, motorcycles, ATV's, boats or any other motorized vehicles.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">3</div>
            <div className="covenant-text">
              <p>No accumulation of discarded personal effects, debris, waste, garbage or other unsightly object or matter will be permitted on any lot.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">4</div>
            <div className="covenant-text">
              <p>No camper or motor home shall be allowed to be lived in, and used as a primary dwelling for more that 60 consecutive days, in the event that a camper or motor home is being used for more than 60 days, the unit must be removed from the property for at least 1 week, and then is allowed to return to the property for an additional 60 day period.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">5</div>
            <div className="covenant-text">
              <p>Any modular or mobile home placed on a lot must be no older than 5 years from the date of placement, and must be underpinned within 6 months of placement with brick, stone or masonry or such material approved by developer.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">6</div>
            <div className="covenant-text">
              <p>Any home that is going to be relocated from another location to this property must be approved by the developer prior to placement on this property.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">7</div>
            <div className="covenant-text">
              <p>All lots shall be for no more than two single family dwelling, with customary out buildings. This is to allow anyone who wishes to build a secondary Mother-in-law type suite on their property in the future.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">8</div>
            <div className="covenant-text">
              <p>No junk vehicles or parts of the same shall be permitted on any lot that is in view of any neighboring property or in view from a public road. Junk vehicles are defined as any motor vehicle parked or placed on any lot that is not registered and licensed.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">9</div>
            <div className="covenant-text">
              <p>No livestock or other animals may be raised or kept for commercial purposes. All swine are prohibited. One large animal PER ACRE (5 acres may have 5 large animals) Large animals are animals such as Cattle or Horses. Small animals are allowed. Cattle and horses raised on property may be sold provided that the one animal per acre limit are observed. Dog kennels are not allowed. Dog kennels are defined by the zoning ordinance of each County.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">10</div>
            <div className="covenant-text">
              <p>No single-wide mobile home shall be permitted.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">11</div>
            <div className="covenant-text">
              <p>No dwelling shall be located nearer than 100 feet from the center line of the fronting road, or 30 feet from a side line, or 50 feet from a rear line.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">12</div>
            <div className="covenant-text">
              <p>All structures erected shall be completed within one year of when work begins.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">13</div>
            <div className="covenant-text">
              <p>No dry van or refrigerated/reefer semi-trailers allowed.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">14</div>
            <div className="covenant-text">
              <p>Timber may not be harvested on any property where owner financing was utilized, and there is still a balance owed to seller, without the prior permission from the seller. Any proceeds from timber harvesting during the time a balance is owed, shall be paid to seller as a principle reduction on your loan.</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">15</div>
            <div className="covenant-text">
              <p>No temporary house or shack shall be erected. (Permanent storage building is allowed).</p>
            </div>
          </div>

          <div className="covenant-item">
            <div className="covenant-number">16</div>
            <div className="covenant-text">
              <p>The Seller reserves the right to give variances and exceptions to these restrictions from time to time.</p>
            </div>
          </div>
        </div>

        <div className="covenants-footer">
          <FontAwesomeIcon icon={faCheckCircle} className="covenant-check-icon" />
          <div className="covenant-footer-text">
            <p>These Covenants are minimums set by <strong>Off Grid Developments LLC</strong>. You must check with your local County Building Office for their minimums, which may be higher.</p>
            <p>These covenants are in addition to any other required governmental authority zoning ordinances.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectiveCovenants; 