import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosConfig';  // Import the centralized Axios instance
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faFileContract, faHandshake, faPhone, faHome, faInfo, faList, faEnvelope, faMapMarkerAlt, faUsers, faGlobe, faHashtag, faCamera, faAt } from '@fortawesome/free-solid-svg-icons';
import House from '../assets/images/about1.jpg';
import ValuesImage from '../assets/images/off 2.jpg';
import off from '../assets/images/off 1.jpg';
import off3 from '../assets/images/off 3.jpg';
import off2 from '../assets/images/off 2.jpg';
import aboutVideo from '../assets/images/about-video.mp4';
import deerImage from '../assets/images/deer.jpg';
import fireImage from '../assets/images/fire.jpg';
import flagdayGif from '../assets/images/gifs/flagday.gif';

const About = () => {
    const [topSeller, setTopSeller] = useState([]);
    const [realtors, setRealtors] = useState([]);

    // Function to get the CSRF token
    const getCSRFToken = () => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, 'csrftoken'.length + 1) === 'csrftoken=') {
                    cookieValue = decodeURIComponent(cookie.substring('csrftoken'.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    useEffect(() => {
        const csrfToken = getCSRFToken();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };

        const getTopSeller = async () => {
            try {
                const res = await axiosInstance.get('/api/realtors/topseller/', config);
                setTopSeller(res.data);
            } catch (err) {
                console.error('Error fetching top seller:', err);
            }
        };

        getTopSeller();
    }, []);

    useEffect(() => {
        const csrfToken = getCSRFToken();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };

        const getRealtors = async () => {
            try {
                const res = await axiosInstance.get('/api/realtors/', config);
                setRealtors(res.data);
            } catch (err) {
                console.error('Error fetching realtors:', err);
            }
        };

        getRealtors();
    }, []);

    const getAllRealtors = () => {
        let allRealtors = [];
        let results = [];

        realtors.forEach(realtor => {
            allRealtors.push(
                <Fragment key={realtor.id}>
                    <div className='about__display'>
                        <img className='about__display__image' src={realtor.photo} alt='' />
                    </div>
                    <h3 className='about__realtor'>{realtor.name}</h3>
                    <p className='about__contact'>{realtor.phone}</p>
                    <p className='about__contact'>{realtor.email}</p>
                    <p className='about__about'>{realtor.description}</p>
                </Fragment>
            );
        });

        for (let i = 0; i < realtors.length; i += 3) {
            results.push(
                <div key={i} className='row'>
                    <div className='col-1-of-3'>
                        {allRealtors[i]}
                    </div>
                    <div className='col-1-of-3'>
                        {allRealtors[i + 1] ? allRealtors[i + 1] : null}
                    </div>
                    <div className='col-1-of-3'>
                        {allRealtors[i + 2] ? allRealtors[i + 2] : null}
                    </div>
                </div>
            );
        }

        return results;
    };

    const getTopSellerContent = () => {
        return topSeller.map(seller => (
            <Fragment key={seller.id}>
                <div className='about__display'>
                    <img className='about__display__image' src={seller.photo} alt='' />
                </div>
                <h3 className='about__topseller'>Top Seller:</h3>
                <p className='about__realtor'>{seller.name}</p>
                <p className='about__contact'>{seller.phone}</p>
                <p className='about__contact'>{seller.email}</p>
                <p className='about__about'>{seller.description}</p>
            </Fragment>
        ));
    };

    const FAQItem = ({ question, answer }) => {
        const [isOpen, setIsOpen] = useState(false);
        const answerRef = useRef(null);

        const toggleOpen = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div className={`faq-item ${isOpen ? 'open' : ''}`}>   
                <div className="faq-question" onClick={toggleOpen}>
                    <span>{question}</span>
                    <span className="faq-icon">{isOpen ? '−' : '+'}</span>
                </div>
                <div className="faq-answer">
                    <p>{answer}</p>
                </div>
            </div>
        );
    };

    const FAQSection = () => {
        const [activeTab, setActiveTab] = useState(0);

        // Group questions into categories
        const faqCategories = [
            {
                title: "Property Information",
                faqs: [
                    {question: 'Does the property include sewer or septic?', answer: 'Due to the location of certain properties, we cannot guarantee each property will have sewer/septic.'},
                    {question: 'Is homesteading allowed on my land?', answer: 'Of course! You can start your own garden, farm, home or build a barn.'},
                    {question: 'Can I obtain multiple properties?', answer: 'Yes, our properties are on a first come first serve basis'},
                    {question: 'How often do you have new properties for sale?', answer: 'We visit land auctions and other wholesaling events across the state frequently. Our properties are on a first come first serve basis. We cannot guarantee anything without accepting a deposit.'},
                    {question: 'Who pays the property taxes?', answer: 'Purchasers are responsible for the annual tax amount. Property taxes are not included into your monthly payment.'},
                    {question: 'Are customers responsible for annual property taxes?', answer: 'Yes, customers are responsible for annual property taxes, which will be billed separately toward the end of the year. These taxes are not included in your monthly payments and must be paid in addition to your financing plan.'}
                ]
            },
            {
                title: "Purchasing & Financing",
                faqs: [
                    {question: 'Do you check credit?', answer: 'No! When you choose to purchase a property from us, we handle all qualification decisions in-house without doing a credit check.'},
                    {question: 'How does it work?', answer: 'First step is to find your land! Click on our interactive listings tab to see available properties. Once you have chosen the right property for you, you can purchase your lot online! You have the options to choose between a payment plan or paying for your lot in a single lump sum. We also encourage you to drive and check out the property prior to purchasing.'},
                    {question: 'How do I buy a property?', answer: 'When you find the perfect property, you can contact us to set up a time to talk to one of our specialists. We will assist you through the process and answer any questions you may have. On the other hand, we also have many clients who buy property right from our site using the purchasing options on the listing page. Select the property then you will have the available option to submit down payment. We will send you the contract for the land. Closings can be finalized in less than a week.'},
                    {question: 'Can I purchase the property with a personal loan or cash?', answer: 'Absolutely! We offer flexible payment options. All closings are done at an attorney office.'},
                    {question: 'When are monthly payments Due?', answer: 'Monthly payments are due the 1st of each month. Payments made 10days after are considered late and a late fee will be charged'},
                    {question: 'Can I lease land from Off Grid Developments LLC?', answer: 'Unfortunately, we do not allow customers to lease.'}
                ]
            },
            {
                title: "Land Development",
                faqs: [
                    {question: 'Can I clear and do land excavation on my property?', answer: 'We allow trees to be cleared and excavation for improvements such as driveways, home, barn etc.. However, it is important that the customer obtains a septic tank permit and land excavation permits prior to any work being done. Before any land excavation is done written consent must be obtain from Offgriddevelopments LLC. Contact admin prior to any excavation.'},
                    {question: 'Can I build on my property?', answer: 'Yes, we require at least 50% of financed amount to be paid off prior to any action. All you need to do is obtain county permits.'},
                    {question: 'What are the steps to getting started building a home?', answer: 'The first step would be to obtain a septic permit for the county local health department. Next, obtain all the necessary documents, permits, and licensure from the county. Lastly, abide by all convents and regulations listed on contractual agreement.'},
                    {question: 'Can I hire a personal soil test scientist to examine the soil prior to purchasing?', answer: 'Yes, we encourage you to do your due diligence and research prior to purchasing.'},
                    {question: 'Are there any HOA fees?', answer: 'No, we do not have Homeowner Association on our farms and properties. However, we do have protective covenants that are listed in our contract and must be followed diligently'}
                ]
            }
        ];

        return (
            <div className="faq-section">
                <h2 className="faq-heading">Frequently Asked Questions</h2>
                
                <div className="faq-tabs">
                    {faqCategories.map((category, index) => (
                        <button 
                            key={index}
                            className={`faq-tab ${activeTab === index ? 'active' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            {category.title}
                        </button>
                    ))}
                </div>
                
                <div className="faq-content">
                    {faqCategories[activeTab].faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="about-page-container">
            <main className='about'>
                <Helmet>
                    <title>Buy Land Online</title>
                    <meta name='description' content='About us' />
                </Helmet>
                
                <section className='about__hero'>
                    <div className='about__hero__background'>
                        <img src={House} alt='Beautiful land property' className='about__hero__image' />
                        <div className='about__hero__overlay'></div>
                    </div>
                    <div className='about__hero__content'>
                        <div className="about__hero__text-container">
                            <h1 className='about__hero__heading'>
                                <span className="highlight">Say Goodbye</span><br />to the Struggle
                                <br />
                                <span className="highlight">Finding the Right Land</span> just got Easier.
                            </h1>
                            <p className='about__hero__paragraph'>
                                Find a variety of properties that suit you perfectly. Forget all difficulties in finding a residence for you.
                            </p>
                            <Link to="/listings" className="about__hero__button">
                                View Available Listings
                            </Link>
                            <div className='about__hero__stats'>
                                <div className='stat'>
                                    <h3 className='stat__number'>100+</h3>
                                    <p className='stat__label'>Land Sold</p>
                                </div>
                                <div className='stat'>
                                    <h3 className='stat__number'>4.8★</h3>
                                    <p className='stat__label'>Rating</p>
                                </div>
                                <div className='stat'>
                                    <h3 className='stat__number'>100%</h3>
                                    <p className='stat__label'>Customer Satisfaction</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='about__values'>
                    <div className='about__values__container'>
                        <div className='about__values__header'>
                            <h2 className='about__values__heading'>Our Values</h2>
                            <div className='about__values__divider'></div>
                        </div>
                        
                        <div className='about__values__grid'>
                            <div className='about__values__card'>
                                <div className='about__values__card-number'>01</div>
                                <div className='about__values__card-content'>
                                    <h3 className='about__values__card-title'>Planning</h3>
                                    <p className='about__values__card-text'>
                                        We focus on meticulous planning to ensure every aspect of the project is considered and executed efficiently.
                                    </p>
                                </div>
                                <div className='about__values__card-image'>
                                    <img src={off2} alt="Planning" />
                                </div>
                                {/* <div className='about__values__card-icon'>
                                    <FontAwesomeIcon icon={faFileContract} />
                                </div> */}
                            </div>
                            
                            <div className='about__values__card'>
                                <div className='about__values__card-number'>02</div>
                                <div className='about__values__card-content'>
                                    <h3 className='about__values__card-title'>Communication</h3>
                                    <p className='about__values__card-text'>
                                        Clear and open communication channels are maintained at all times, ensuring that everyone is on the same page.
                                    </p>
                                </div>
                                <div className='about__values__card-image'>
                                    <img src={deerImage} alt="Communication" />
                                </div>
                                {/* <div className='about__values__card-icon'>
                                    <FontAwesomeIcon icon={faUsers} />
                                </div> */}
                            </div>
                            
                            <div className='about__values__card'>
                                <div className='about__values__card-number'>03</div>
                                <div className='about__values__card-content'>
                                    <h3 className='about__values__card-title'>Fastness</h3>
                                    <p className='about__values__card-text'>
                                        We prioritize speed without compromising on quality, delivering results in a timely manner.
                                    </p>
                                </div>
                                <div className='about__values__card-image'>
                                    <img src={fireImage} alt="Fastness" />
                                </div>
                                {/* <div className='about__values__card-icon'>
                                    <FontAwesomeIcon icon={faHandshake} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className='about__reasons'>
                    <h2 className='about__reasons__heading'>Why Choose Us</h2>
                    <div className='row'>
                        <div className='col-1-of-4'>
                            <div className='card'>
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faUserShield} />
                                </div>
                                <h3>Expertise and Local Knowledge</h3>
                                <p>Our seasoned professionals possess extensive experience and insight into the local real estate market,
                                     ensuring you make informed decisions.</p>
                            </div>
                        </div>
                        <div className='col-1-of-4'>
                            <div className='card'>
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faFileContract} />
                                </div>
                                <h3>Transparent and Trustworthy Transactions</h3>
                                <p>Trust and transparency are at the heart of our business. Offering you peace of mind with open communication, honest advice, and straightforward dealings.</p>
                            </div>
                        </div>
                        <div className='col-1-of-4'>
                            <div className='card'>
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faHandshake} />
                                </div>
                                <h3>Customized Solutions and Flexibility</h3>
                                <p>Our flexible approach allows us to tailor our services to meet your unique needs, providing you with personalized solutions for a successful transaction.</p>
                            </div>
                        </div>
                        <div className='col-1-of-4'>
                            <div className='card'>
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>
                                <h3>Strong Network and Connections</h3>
                                <p>Benefit from our robust network of industry professionals and exclusive opportunities, giving you access to the best properties and investment options.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="faq-container">
                    <FAQSection />
                </div>
                <footer className='footer'>
                    <div className='container'>
                        <div className='footer__section'>
                            <h3>Off Grid Developments</h3>
                            <ul>
                                <li>
                                    <Link to="/"><FontAwesomeIcon icon={faHome} /> Home</Link>
                                </li>
                                
                                <li>
                                    <Link to="/company"><FontAwesomeIcon icon={faInfo} /> Company</Link>
                                </li>
                                
                                <li>
                                    <Link to="/listings"><FontAwesomeIcon icon={faList} /> Listings</Link>
                                </li>
                                {/* <li>
                                    <Link to="/contact"><FontAwesomeIcon icon={faEnvelope} /> Contact</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className='footer__contact'>
                            <h3>Contact</h3>
                            <p><FontAwesomeIcon icon={faMapMarkerAlt} /> P.O BOX 81541, Conyers, GA 30013</p>
                            <p><FontAwesomeIcon icon={faPhone} /> 678-870-5285</p>
                            <p><FontAwesomeIcon icon={faEnvelope} /> Admin@mylandgo.com</p>
                            <p><FontAwesomeIcon icon={faUsers} /> Monday-Friday 9am-5pm EST</p>
                        </div>
                    </div>
                    <div className='footer__bottom'>
                        <p>&copy; {new Date().getFullYear()} Off Grid Developments LLC. All Rights Reserved.</p>
                    </div>
                </footer>
            </main>
        </div>
    );
};

export default About;

