import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Initialize navbar effects
function initNavbar() {
    const handleScroll = () => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            const currentPage = window.location.pathname;
            
            // Apply initial page-specific classes
            if (currentPage.includes('/listings')) {
                navbar.classList.add('navbar-listings');
                navbar.classList.add('scrolled'); // Always keep scrolled class for listings
            } else if (currentPage.includes('/company')) {
                navbar.classList.add('navbar-company');
                navbar.classList.add('scrolled'); // Always keep scrolled class for company
            } else {
                navbar.classList.add('navbar-about');
                
                // Only apply scroll effects to About page
                if (window.scrollY > 50) {
                    navbar.classList.add('scrolled');
                } else {
                    navbar.classList.remove('scrolled');
                }
            }
        }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Check scroll position on initial load
    handleScroll();
}

document.addEventListener('DOMContentLoaded', () => {
    initNavbar();
});
