import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ itemsPerPage, count, active, visitPage, previous, next }) => {
    const getNumbers = () => {
        let numbers = [];
        let totalPages = Math.ceil(count / itemsPerPage);
        
        console.log(`Pagination: total=${count}, itemsPerPage=${itemsPerPage}, totalPages=${totalPages}, active=${active}`);

        for (let i = 1; i <= totalPages; i++) {
            let style = 'pagination__number';
            let content = null;

            if (active === i) {
                style = 'pagination__number pagination__number--active';
                content = (
                    <div key={i} className={style}>
                        {i}
                    </div>
                );
            } else {
                content = (
                    <div key={i} onClick={() => visitPage(i)} className={style}>
                        {i}
                    </div>
                );
            }

            numbers.push(content);
        }

        return numbers;
    };

    return (
        <div className='pagination'>
            <div 
                onClick={previous} 
                className={`pagination__number ${!previous ? 'pagination__number--disabled' : ''}`}
            >
                Previous
            </div>
            {getNumbers()}
            <div 
                onClick={next} 
                className={`pagination__number ${!next ? 'pagination__number--disabled' : ''}`}
            >
                Next
            </div>
        </div>
    );
};

Pagination.propTypes = {
    itemsPerPage: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    active: PropTypes.number.isRequired,
    visitPage: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
};

export default Pagination;
