import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      minHeight: '100vh',
      paddingTop: '12rem',
      textAlign: 'center'
    }}>
      <h1 style={{ 
        fontSize: '24px', 
        color: "green", 
        marginBottom: '4rem'
      }}>
        Payment Successful!
      </h1>
      <p style={{ 
        fontSize: '20px', 
        color: "black", 
        fontWeight: "bold",
        marginBottom: '3rem',
        maxWidth: '90%',
        '@media (max-width: 768px)': {
          fontSize: '18px'
        }
      }}>
        Thank you for choosing Off Grid Developments.
      </p>

      <Link 
        to='/' 
        style={{
          display: 'inline-block',
          padding: '16px 32px',
          backgroundColor: 'green',
          color: 'white',
          borderRadius: '5px',
          textDecoration: 'none',
          fontWeight: 'bold',
          fontSize: "16px",
          transition: 'all 0.3s ease',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)'
          },
          '@media (max-width: 768px)': {
            padding: '14px 28px',
            fontSize: '14px'
          }
        }}
      >
        Go back to Home
      </Link>
    </div>
  );
};

export default Success;
