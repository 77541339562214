import React from 'react';
import { Link } from 'react-router-dom';
import about from '../assets/images/about2.jpg';
import company from '../assets/images/company.jpg';
import company1 from '../assets/images/company1.jpg';
import company2 from '../assets/images/company2.jpg';
import company3 from '../assets/images/company3.jpg';
import company4 from '../assets/images/company4.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHandshake, faUsers, faLightbulb, faTruck, faLeaf, faSmile } from '@fortawesome/free-solid-svg-icons';

const Company = () => {
  return (
    <div className="company-page">
      {/* Hero Section */}
      {/* <div className="company-hero">
        <div className="company-hero-overlay">
          
          <p className='company-hero-title'>We are a family-driven business that values the significance of owning land and turning dreams into reality.</p>
          <Link to="/listings" className="hero-button">View Available Listings</Link>
        </div>
        <img src={company2} alt="Company Hero" className="company-hero-image" />
      </div> */}
      {/* About Section */}
      <div className="company-about">
        <div className="about-content">
          <h2>Why invest with Off Grid Developments?</h2>
          <p>
            Our company prioritizes customer flexibility and accessibility. 
            We understand that purchasing land should be simple and stress-free, which is why we offer customized payment plans to fit your budget. 
            With our owner financing options, you can secure your ideal property without the hassle of traditional bank loans, credit checks, or excessive paperwork.
            Our streamlined process ensures you can start building your future with low down payments and affordable monthly installments.
          </p>
          {/* <div className="company-features">
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="feature-icon" />
              <span>Flexible Owner Financing</span>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="feature-icon" />
              <span>No Credit Checks</span>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="feature-icon" />
              <span>10+ Years Experience</span>
            </div>
          </div> */}
          {/* <Link to="/listings" className="cta-button">Our Properties</Link> */}
        </div>
      </div>


      {/* Three Column Feature Section */}
      <div className="feature-columns">
        <div className="feature-column">
          <img src={company4} alt="Everything you need" className="feature-image" />
          <h3>Start your own Farm</h3>
          <p>
            Off Grid Developments LLC is a dedicated land acquisition and sales company with a mission to make land ownership accessible to all Americans at affordable prices. 
            For over a decade, the company has specialized in buying and selling land throughout the United States, with a particular focus on providing opportunities for those seeking to establish off-grid or self-sufficient lifestyles.
          </p>
          
        </div>
        
        <div className="feature-column">
          <img src={company1} alt="Sourced from producers" className="feature-image" />
          <h3>Build your own Dream Home</h3>
          <p>
            What distinguishes Off Grid Developments is their commitment to financial accessibility through flexible owner financing options, eliminating the traditional barriers of bank loans and stringent credit requirements. This approach has allowed countless individuals to achieve their dream of land ownership who might otherwise be excluded from conventional real estate markets.
          </p>
        </div>
        
        <div className="feature-column">
          <img src={company3} alt="Customer service" className="feature-image" />
          <h3>Enjoy the Wild Outdoors</h3>
          <p>
            With more than 10 years of industry experience, our company has established itself as a trusted partner in helping Americans secure their piece of the country, whether for homesteading, investment, recreation, or future planning. 
            The company continues to fulfill its founding vision of democratizing land ownership across America.
          </p>
          {/* <p className='testimonial-text'>
          "We are a family-driven business that values the significance of owning land and turning dreams into reality."

          </p> */}
        </div>
      </div>

      
      {/* Testimonial Section */}
      <div className="testimonials-section">
        <h2>Customer Testimonials</h2>
        <div className="testimonials-container">
          <div className="testimonial-card">
            <p className="testimonial-text">
              "Buying land was an incredible experience from start to finish. The process was smooth, transparent, and stress-free. Their team was knowledgeable, answered all my questions, and made financing simple with simple flexible payment options. I didn't feel pressured and feel confident with my purchase."
            </p>
            <p className="testimonial-author">~ Erica Wilson.<br/></p>
          </div>
          
          <div className="testimonial-card" style={{ 
            boxShadow: '0 6px 20px rgba(113, 140, 53, 0.6)', 
            borderLeft: '4px solid hsl(124, 47%, 43%)',
            transform: 'translateY(-5px)'
          }}>
            <p className="testimonial-text">
              "This is legit! Their low upfront costs is ideal for those looking to start investing. I was able to sign and secure my farm on the same day! I would recommend this company to anybody interested in starting a homestead or enjoys being outdoors."
            </p>
            <p className="testimonial-author">~ Samuel Castillo.<br/></p>
          </div>
          
          <div className="testimonial-card">
            <p className="testimonial-text">
              "I have purchased multiple properties with this company. Each parcel offers a unique feel and experience. Every summer, me and my son go hunting for deers and wild turkeys. We love it and we'll be purchasing more land in the near future!"<br/>✨✨✨✨✨
            </p>
            <p className="testimonial-author">~ Nathan Wheeler<br/></p>
          </div>
        </div>
      </div>
       
      <div className="company-hero">
        <div className="company-hero-content">
          <img src={company2} alt="Family enjoying outdoor camping" className="company-hero-image" />
          <div className="company-hero-text">
            <p className='company-hero-title'>"We are a family-driven business that values the significance of owning land and turning dreams into reality."</p>
            <p className="company-hero-attribution">~ Off Grid Developments LLC</p>
          </div>
        </div>
      </div>


      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-content">
          <h2>Ready to find your perfect land?</h2>
          <Link to="/listings" className="cta-button-large">View Available Listings</Link>
        </div>
      </div>
    </div>
  );
};

export default Company;
