import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/auth';
import logo from '../assets/images/logo2.png';
import Alert from './Alert';

const Navbar = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, loading } = useSelector(state => state.auth);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();
    
    // Determine the current page to add the appropriate class
    const getPageClass = () => {
        const path = location.pathname;
        if (path === '/') return 'navbar-about';
        if (path.includes('/listings')) return 'navbar-listings';
        if (path === '/company') return 'navbar-company';
        if (path === '/protective-covenants') return 'navbar-listings'; // Using same style as listings
        return '';
    };

    useEffect(() => {
        // Determine current page based on URL
        const path = window.location.pathname;
        let currentPage = 'about'; // Default page
        
        if (path.includes('/listings')) {
            currentPage = 'listings';
        } else if (path.includes('/company')) {
            currentPage = 'company';
        } else if (path.includes('/protective-covenants')) {
            currentPage = 'listings'; // Using same behavior as listings
        }
        
        setScrolled(currentPage === 'listings' || currentPage === 'company');
        
        // Set appropriate class based on page type
        const navbarElement = document.querySelector('.navbar');
        if (navbarElement) {
            navbarElement.classList.add(`navbar-${currentPage}`);
        }
        
        // For Listings and Company pages, always add 'scrolled' class to keep navbar visible
        if (currentPage === 'listings' || currentPage === 'company') {
            if (navbarElement) {
                navbarElement.classList.add('scrolled');
            }
        }
        
        // Only add scroll event listener for About page
        const handleScroll = () => {
            const navbarElement = document.querySelector('.navbar');
            
            if (currentPage === 'about') {
                // Apply scroll effects only for About page
                if (window.scrollY > 100) {
                    if (navbarElement) {
                        navbarElement.classList.add('scrolled');
                    }
                } else {
                    if (navbarElement) {
                        navbarElement.classList.remove('scrolled');
                    }
                }
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        
        // Initial call to set correct state
        handleScroll();
        
        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const authLinks = (
        <a className='navbar__auth__link' onClick={() => dispatch(logout())} href='#!'>Logout</a>
    );

    const guestLinks = (
        <>
            {/* <Link className='navbar__auth__link' to='/login'>Login</Link>
            <Link className='navbar__auth__link' to='/signup'>Register</Link> */}
        </>
    );

    return (
        <>
            <nav className={`navbar ${scrolled ? 'scrolled' : ''} ${getPageClass()}`}>
                <div className="navbar__container">
                    <Link className='navbar__logo' to='/'>
                        <img src={logo} alt='Offgriddevelopments LLC' className='navbar__logo__image' />
                    </Link>

                    <div className="navbar__nav">
                        <ul className='navbar__menu'>
                            <li className='navbar__menu__item'>
                                <NavLink className='navbar__menu__item__link' exact to='/'>Home</NavLink>
                            </li>
                            <li className='navbar__menu__item'>
                                <NavLink className='navbar__menu__item__link' exact to='/listings'>Listings</NavLink>
                            </li>
                            <li className='navbar__menu__item'>
                                <NavLink className='navbar__menu__item__link' exact to='/company'>Company</NavLink>
                            </li>
                            <li className='navbar__menu__item'>
                                <NavLink className='navbar__menu__item__link' exact to='/protective-covenants'>Protective Covenants</NavLink>
                            </li>
                        </ul>
                        
                        <div className='navbar__auth'>
                            {!loading && (<>{isAuthenticated ? authLinks : guestLinks}</>)}
                        </div>
                    </div>
                </div>
            </nav>
            <Alert />
        </>
    );
};

export default Navbar;
